<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 mb-5 pt-2 pb-3">
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="keyword"
            @keyup.enter="getData()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          sm="12"
          class="mt-2"
          style="white-space: nowrap"
        >
          <v-btn color="primary" class="mr-2" @click="getData()">검색</v-btn>
          <v-btn @click="refresh()">초기화</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :page.sync="page"
          :items-per-page="per_page"
          loading-text="로딩중.. 기다려주세요."
          no-data-text="데이터가 없습니다."
          elevation="0"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>카테고리: {{ total }}개</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary white--text"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    style="font-weight: bold"
                    >카테고리 등록
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-toolbar-title
                        >카테고리 {{ formTitle }}</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn icon dark @click="dialog.value = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.name"
                              label="카테고리"
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        :disabled="!valid"
                        color="blue darken-1 white--text"
                        @click="save()"
                        >{{ formTitle }}
                      </v-btn>
                      <v-btn
                        color="primary white--text"
                        @click="dialog.value = false"
                        >닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-1" color="green darken-1" @click="editItem(item)">
              mdi-pencil-box-outline
            </v-icon>
            <v-icon class="mr-1" color="red darken-1" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-2"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CategoryManage",
  data() {
    return {
      headers: [
        {
          text: "NO",
          value: "no",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "카테고리",
          value: "name",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "언론사 수",
          value: "medias",
          sortable: true,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정/삭제",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
      ],
      loading: false,
      items: [],

      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
      },

      page: 1,
      per_page: 10,
      pageCount: 1,
      total: 0,
      keyword: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "등록" : "수정";
    },
    valid() {
      if (this.editedItem.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    page() {
      this.getData();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    refresh() {
      this.keyword = "";
      this.getData();
    },
    getData() {
      this.loading = true;
      let items = [];
      let params = {
        page: this.page,
        per_page: this.per_page,
        name: this.keyword,
      };
      this.axios
        .get(`api/v1/admin/categories`, { params })
        .then((res) => {
          if (res.data.data.categories.length > 0) {
            res.data.data.categories.map((item, i) => {
              let index =
                res.data.data.totalCount - (this.page - 1) * this.per_page - i;
              items.push({
                no: index,
                id: item.id,
                name: item.name,
                medias: item.medias.length,
              });
            });
          }
          this.total = res.data.data.totalCount;
          this.items = items;
          if (
            Number.isInteger(res.data.data.totalCount / this.per_page) === false
          ) {
            this.pageCount =
              Math.floor(res.data.data.totalCount / this.per_page) + 1;
          } else {
            this.pageCount = res.data.data.totalCount / this.per_page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        const data = {
          name: this.editedItem.name,
        };
        this.axios
          .put("api/v1/admin/categories/" + this.editedItem.id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `카테고리가 수정되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      } else {
        this.items.push(this.editedItem);
        const data = {
          name: this.editedItem.name,
        };
        this.axios
          .post("api/v1/admin/categories", data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `카테고리가 ${this.formTitle}되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      }
      this.getData();
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$Swal
        .fire({
          text: "삭제 하시겠습니까?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          confirmButtonColor: "#1D72DF",
          denyButtonColor: "#DC2B30",
          width: "400px",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .delete("api/v1/admin/categories/" + this.editedItem.id)
              .then(() => {
                this.getData();
              });
          } else if (result.isDenied) {
            this.closeDelete();
          }
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
